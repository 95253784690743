import classNames from "classnames";
import { useTheme } from "../../../theme/useTheme";
import { T } from "@config/lang";
import { useSearchBoxTabs } from "@presentation/shared/components/search-box/useSearchBoxTabs";

export default function SearchBoxTabs() {
  const { searchBox } = useTheme();
  const { tabs, isActive, setSearchType } = useSearchBoxTabs();

  return (
    <div className={classNames(searchBox.tabsContainer)}>
      <div className={classNames(searchBox.tabs)}>
        {tabs.map(([key, searchType]) => (
          <div
            key={key}
            className={classNames([
              "cursor-pointer",
              searchBox.tab.any,
              isActive(key) ? searchBox.tab.active : searchBox.tab.inactive,
            ])}
            onClick={() => setSearchType(key)}
          >
            {T[`search_type_${searchType}`]}
          </div>
        ))}
      </div>
    </div>
  );
}
