import { Settings as defaults } from "./settings.default";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import merge from "lodash.merge";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { LANGUAGE } from "@config/lang";
import { SEARCH_TYPES } from "@config/const/enums";

// <link
//       href="https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap"
//       rel="stylesheet"
//     />

export const Settings = merge(defaults, {
  endpoint: "https://api.etc.mk/",
  languages: [LANGUAGE.MK, LANGUAGE.SQ, LANGUAGE.EN],
  encodeUrl: false,
  hashRouter: true,
  defaultLanguage: "mk",
  links: {
    top: ["phone", "email", "b2b", "lang"],
    menu: [],
  },
  menuLinks: [],
  googleAnalyticsTrackingId: null,
  contact: {
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.1921231119754!2d21.425102011687862!3d41.99615217110904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135415b4b2c556d7%3A0x782bfd3b690ddcf6!2sETC%20Travel!5e0!3m2!1sen!2smk!4v1686415349035!5m2!1sen!2smk",
  },
  social: [
    {
      name: "Facebook",
      Icon: AiFillFacebook,
      url: "https://facebook.com/www.etc.mk/",
    },
    {
      name: "Instagram",
      Icon: AiFillInstagram,
      url: "https://www.instagram.com/etctravel/",
    },
  ],
  search: {
    order: [SEARCH_TYPES.charter, SEARCH_TYPES.hotel],
    charter: {
      displayDepartures: true,
      extraDays: 1,
    },
    hotel: {
      displayDepartures: false,
    },
  },
  booking: {
    show_extend_hotel_night: false,
    passengers: {
      require_passport_no: true,
    },
    payments: [
      {
        type: PaymentTypeEnum.Cash,
        label: "payments.cash",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.CreditCard,
        label: "payments.cc",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.Invoice,
        label: "payments.inv",
        public: false,
        agent: true,
      },
    ],
  },
  iframe: {
    allowBooking: false,
  },
  b2b: {
    bookings: {
      show_document_download: false,
    },
  },
});
