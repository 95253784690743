import * as React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useService } from "@infrastructure/api/hooks/use-service";
import { Requests } from "@infrastructure/api/requests";
import { Axios } from "@infrastructure/api/http-client";
import { useApp } from "@application/context/app-context";

export type UserContextType = {
  loggedIn: boolean;
  token: string;
  user?: {
    id: number;
    company_id: number;
    name: string;
    email: string;
    username: string;
    user_type: string;
    language: string;
  };
  company?: {
    id: number;
    name: string;
    currency: string;
    currency_value: string;
    commission_procent: null;
    commission_price: null;
    language: string;
  };
  login(credentials: { username: string; password: string }): void;
  loginLoading: boolean;
  loginError: boolean;
  logout(): void;
};

const UserContext = createContext<UserContextType>(null);

export const useUser = () => useContext(UserContext);

export function UserProvider({ children }) {
  const value = useProvideUser();
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

const TOKEN_KEY = "id_token";

function useProvideUser() {
  const { setLanguage } = useApp();

  const token = localStorage.getItem(TOKEN_KEY) || null;
  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const logout = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY);
    setUser(null);
  }, []);

  useEffect(() => {
    // Response interceptor for API calls
    Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        // const originalRequest = error.config;
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }, [logout]);

  const { requestAsync: loginRequest } = useService(Requests.login);

  const login = (credentials) => {
    setLoginLoading(true);
    loginRequest(credentials)
      .then((data) => {
        if (data.access_token) {
          localStorage.setItem(TOKEN_KEY, data.access_token);
          setLoginError(false);
          if (data.language) {
            setLanguage(data.language);
          }
        } else if (data.error) {
          setLoginError(true);
        }
      })
      .catch(() => {
        setLoginError(true);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  const { requestAsync } = useService(Requests.me);

  useEffect(() => {
    if (token) {
      requestAsync().then((data) => setUser(data));
    }
  }, [requestAsync, token]);

  return {
    loggedIn: !!token,
    token,
    ...user,
    login,
    loginLoading,
    loginError,
    logout,
  };
}
