import { useSearchRepository } from "@presentation/shared/context/search-repository-context";
import { Settings } from "@config/tenants/settings.default";
import { SEARCH_TYPES } from "@config/const/enums";

export function useSearchBoxTabs() {
  const { current, setSearchType } = useSearchRepository();
  const tabs =
    Settings.search.order?.map((it) => [it, SEARCH_TYPES[it]]) ??
    Object.entries(SEARCH_TYPES);

  const isActive = (tab) => current.searchType === tab;

  return { tabs, isActive, setSearchType };
}
